const en = {

    personal_leave: "Personal Leave",
    sick_leave: "Sick Leave",
    halfday_am: "Half Day (AM)",
    halfday_pm: "Half Day (PM)",
    full_day: "Full Day",
    approve: "Approved",
    awaiting: "Awaiting",
    rejected: "Rejected",
    canceled: "Canceled",
    team: [
        "CEO", "CTO", "Product", "Data","Business", "Design", "Tech", "Admin", "Content", "Marketing", "Internship", "ETC."
    ],
    position: [
        "Administrator", "Android Developer", "Back End Developer", "Business Analyst", "Business Development", "Content Creator", "Data Engineer", "Data Scientist", "Database Administration"
        , "DevOps Engineer", "Web Designer", "Digital Marketing", "Front End Web Developer", "Graphic Design", "iOS Developer", "IT Consulting", "IT Project Manager",
        "IT Security", "Network Administrator", "Online Marketing", "Product Manager", "Programmer", "QA Engineer", "Scrum Master", "Software Engineer", "Software Tester",
        "Support Help Desk", "System Analyst", "UX/UI Designer", "Web Administrator and SEO"
    ]

};

const th = {

    personal_leave: "Personal Leave",
    sick_leave: "Sick Leave",
    halfday_am: "Half Day (AM)",
    halfday_pm: "Half Day (PM)",
    full_day: "Full Day",
    approve: "Approved",
    awaiting: "Awaiting",
    rejected: "Rejected",
    canceled: "Canceled",
    team: [
        "CEO", "CTO", "Product", "Business", "Design", "Tech", "Admin", "Content", "Marketing", "Internship", "ETC."
    ],
    position: [
        "Administrator", "Android Developer", "Back End Developer", "Business Analyst", "Business Development", "Content Creator", "Data Engineer", "Data Scientist", "Database Administration"
        , "DevOps Engineer", "Web Designer", "Digital Marketing", "Front End Web Developer", "Graphic Design", "iOS Developer", "IT Consulting", "IT Project Manager",
        "IT Security", "Network Administrator", "Online Marketing", "Product Manager", "Programmer", "QA Engineer", "Scrum Master", "Software Engineer", "Software Tester",
        "Support Help Desk", "System Analyst", "UX/UI Designer", "Web Administrator and SEO"
    ]


};
const config_language = process.env.REACT_APP_LANGUAGE === 'en'
    ? en
    : th;
export default {
    // Add common config values here
    ...config_language
};