import { collection, getDocs, query, Timestamp, where } from "firebase/firestore";
import LeaveModel from "../../../model/leave.model";
import "../admin-dashboard/admin-dashboard.scss";
import "./admin-awaiting.scss";
import React, { useState } from 'react';
import configLanguage from "../../../config-language";
import ApiServices from "../../../services/api.service";
import EMPTY_IMG from "../../../assets/images/empty.png";
import Moment from "react-moment";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import { initializeApp } from "firebase/app";
const AdminAwaitingComponent = ({ dbAccess }: any) => {
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const db: any = dbAccess;
    const navigate = useNavigate();
    const [awList, setAwList] = useState<LeaveModel[]>();
    const [email , setEmail] = useState("");
    const getAwaitingList = async () => {
        const q = query(collection(db, "leave"), where("statusleave", "==", configLanguage.awaiting));
        const querySnapshot = await getDocs(q);
        const leavetd: LeaveModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as LeaveModel;
            dataRes.docId = doc.id
            leavetd.push(dataRes);
        });
        setAwList(leavetd)
    }
    const checkLogin = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                if (user.email === "benz@scoutout.net" || user.email === "tom@scoutout.net" || user.email === "thanut@scoutout.net") {
                    setEmail(user.email);
                    getAwaitingList();
                } else {
                    navigate('/login?redirect_url=admin');
                }
            } else {
                navigate('/login?redirect_url=admin');
            }
        });
    }
    const clickApprove = async (awList: LeaveModel) => {

        const objectRes = {
            statusleave: configLanguage.approve,
            approveDateCreate: Timestamp.now(),
            action_by: email
        }

        ApiServices().approveLeave(awList.docId, objectRes).then((res: any) => {

            getAwaitingList();
        })
    }

    const clickReject = async (awList: LeaveModel) => {
        const objectRes = {
            statusleave: configLanguage.rejected,
            approveDateCreate: Timestamp.now(),
            action_by: email
        }

        ApiServices().approveLeave(awList.docId, objectRes).then((res: any) => {
            getAwaitingList();
        })

    }

    React.useEffect(() => {
        checkLogin();
     
    }, [])
    return (
        <>

            <div className="admin-awaiting">
                <div className="info-control">
                    {
                        awList?.length === 0 ?

                            <div className="d-flex flex-column">
                                <img src={EMPTY_IMG} className="not-found-img mx-auto"></img>
                                <span className="not-found-text">ไม่มีรายการ</span>
                            </div>
                            :
                            awList?.map((item: LeaveModel, index: number) => {

                                return (
                                    <div className="card-control" key={index}>
                                        <span className="name-text">{item.employeenickname}</span>
                                        <span className="desc-text">{item.employeename}</span>
                                        <div className="d-flex">
                                            <span className="desc-text my-auto">ประเภทการลา: </span>
                                            <span className="value-text my-auto">{item.leavetype}</span>
                                        </div>
                                        <div className="d-flex">
                                            <span className="desc-text my-auto">วันที่ลา: </span>
                                            <span className="value-text my-auto">
                                                {
                                                    item.dateleavefrom.toDate().toDateString() === item.dateleaveto.toDate().toDateString() ?

                                                        <Moment format="DD-MMM-YYYY">
                                                            {item.dateleavefrom.toDate()}
                                                        </Moment> :
                                                        <>
                                                            <Moment format="DD-MMM-YYYY">
                                                                {item.dateleavefrom.toDate()}
                                                            </Moment> -   <Moment format="DD-MMM-YYYY">
                                                                {item.dateleaveto.toDate()}
                                                            </Moment>
                                                        </>
                                                }

                                            </span>
                                        </div>
                                        <div className="d-flex">
                                            <span className="desc-text my-auto">จำนวนวันลา: </span>
                                            <span className="value-text my-auto">{item.leavedaytotal} วัน</span>
                                        </div>
                                        <div className="d-flex">
                                            <span className="desc-text my-auto">เหตุผล: </span>
                                            <span className="value-text my-auto">{item.des}</span>
                                        </div>
                                        <div className="d-flex">
                                            <button className="approve-button" onClick={() => clickApprove(item)}>Approve</button>
                                            <button className="reject-button" onClick={() => clickReject(item)}>Reject</button>
                                        </div>
                                    </div>
                                )
                            })
                    }

                </div>

            </div>

        </>
    )
}

export default AdminAwaitingComponent;