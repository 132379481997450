import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, Timestamp, where } from "firebase/firestore";
import config from "../../../config";
import configLanguage from "../../../config-language";
import LeaveModel from "../../../model/leave.model";
import React, { useState } from 'react';

import "./announcement.scss";
import Carousel from "react-multi-carousel";
import HolidaysModel from "../../../model/holidays.model";
import Moment from "react-moment";

const AnnouncementComponent = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 150 },
            items: 1,
            slidesToSlide: 1// optional, default to 1.
        }
    };
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const startOfToday = new Date();
    startOfToday.setDate(startOfToday.getDate() - 1)
    const todayDate = new Date();
    const [empLeaveToday, setEmpLeaveToday] = React.useState<LeaveModel[]>();
    const [empLeaveSoon, setEmpLeaveSoon] = React.useState<LeaveModel[]>();
    const [nextHoliday, setNextHoliday] = React.useState<HolidaysModel>();
    const tmrDate = new Date();
    tmrDate.setDate(tmrDate.getDate() + 1);
    const getUsersLeaveToday = async () => {
        const q = query(collection(db, "leave"),
            where("dateleaveto", ">=", startOfToday),
            where("statusleave", "==", configLanguage.approve)
        );
        const querySnapshot = await getDocs(q);
        const leavetd: LeaveModel[] = [];
        const leavesn: LeaveModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as LeaveModel;
            getDates2Day(dataRes.dateleavefrom.toDate(), dataRes.dateleaveto.toDate())
                .map((item: Date, index: number) => {
                    if (item.toDateString() === todayDate.toDateString()) {
                        leavetd.push(dataRes);
                    } else {
                        if (item.toDateString() === tmrDate.toDateString()) {
                            leavesn.push(dataRes);
                        }
                    }
                })
            //   setProfileRes(dataRes);
        });
        setEmpLeaveToday(leavetd)
        setEmpLeaveSoon(leavesn)
    }
    const nextHolidays = async () => {
        const q = query(collection(db, "holidays"),
            where("date", ">=", startOfToday),
            limit(1)
        );
        const querySnapshot = await getDocs(q);
        const leavetd: HolidaysModel[] = [];
        querySnapshot.forEach((doc) => {
            const dataRes = doc.data() as HolidaysModel;
            setNextHoliday(dataRes);
        });
    }
    const addDays = (date: any, days: any) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    React.useEffect(() => {

        getUsersLeaveToday();
        nextHolidays();
    },[])


    const getDates2Day = (startDate: Date, stopDate: Date) => {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate = addDays(currentDate, 1);
        }
        return dateArray;
    }
    return (

        <>
            <div className="announcement">
                <span className="announcement-text">Announcement</span>
                <Carousel
                    responsive={responsive}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    autoPlay
                    infinite
                    transitionDuration={2000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {
                        empLeaveToday &&  <span className="announcement-detail-header">Today leave: {

                            empLeaveToday?.map((item: LeaveModel, index: number) => {
    
                                return (
                                    <>
                                    {
                                        index === empLeaveToday.length-1 ? 
                                        <span className="announcement-detail" key={index}>{
                                            item.employeenickname
                                        }</span> : 
                                        <span className="announcement-detail" key={index}>{
                                            item.employeenickname
                                        }, </span>

                                    }
                                       
                                    </>
                                )
                            })
                        }</span>
                    }
                   {
                        empLeaveSoon &&  <span className="announcement-detail-header">Tomorrow leave: {

                            empLeaveSoon?.map((item: LeaveModel, index: number) => {
    
                                return (
                                    <>
                                         {
                                        index === empLeaveSoon.length-1 ? 
                                        <span className="announcement-detail"  key={index}>{
                                            item.employeenickname
                                        }</span> : 
                                        <span className="announcement-detail"  key={index}>{
                                            item.employeenickname
                                        }, </span>

                                    }
                                    </>
                                )
                            })
                        }</span>
                    }
                     {
                        nextHoliday &&  <span className="announcement-detail-header">Next Holiday: <span className="announcement-detail">
                            {nextHoliday.title} (
                            <Moment format="ddd DD-MMM-YYYY" >
                                {nextHoliday.date.toDate()}
                            </Moment>)
                            </span></span>
                    }
                </Carousel>
            </div>
        </>
    )
}

export default AnnouncementComponent;