import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom"
import config from "../../config";
import AdminApprovedComponent from "./admin-approved/admin-approved";
import AdminAwaitingComponent from "./admin-awaiting/admin-awaiting";
import AdminDashboardComponent from "./admin-dashboard/admin-dashboard"
import AdminEmployeesComponent from "./admin-employees/admin-employees";
import AdminRejectedComponent from "./admin-rejected/admin-rejected";
import "./admin.scss";
import React, { useState } from 'react';
const AdminComponent = () => {
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    let navigate = useNavigate();
    React.useEffect(() => {

        // checkLogin();
    }, [])
    return (

        <div className="admin">
            <AdminDashboardComponent />

        </div>
    )
}

export default AdminComponent;