import TextResult from "../../../model/personality.model";
import React, { useState } from 'react';
import "./mbti-detail.scss";

const MBTIDetailComponent = ({ personalityGraphs }: any) => {

    const personalityGraph: TextResult[] = personalityGraphs


    return (

        <>
            {
                personalityGraph &&

                <div className="d-flex flex-column mbti-detail">

                    <div className='d-block mt-auto'>

                        <div className="ocean-control d-flex flex-column">
                            <div className='d-flex flex-column'>
                                <span className="ocean-header">
                                    {((personalityGraph[0]?.big_5_user_score) * 100).toFixed(0)} %
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[0]?.big_5_trait_full_en}
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[0]?.big_5_trait_full_th}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[0]?.big_5_trait_text_en}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[0]?.big_5_trait_text_th}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='d-block my-auto'>
                        <div className="ocean-control">
                            <div className='d-flex flex-column'>
                                <span className="ocean-header">
                                    {((personalityGraph[1]?.big_5_user_score) * 100).toFixed(0)} %
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[1]?.big_5_trait_full_en}
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[1]?.big_5_trait_full_th}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[1]?.big_5_trait_text_en}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[1]?.big_5_trait_text_th}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='d-block my-auto'>
                        <div className="ocean-control">
                            <div className='d-flex flex-column'>
                                <span className="ocean-header">
                                    {((personalityGraph[2]?.big_5_user_score) * 100).toFixed(0)} %
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[2]?.big_5_trait_full_en}
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[2]?.big_5_trait_full_th}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[2]?.big_5_trait_text_en}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[2]?.big_5_trait_text_th}
                                </span>
                            </div>


                        </div>
                    </div>
                    <div className='d-block my-auto'>
                        <div className="ocean-control">
                            <div className='d-flex flex-column'>
                                <span className="ocean-header">
                                    {((personalityGraph[3]?.big_5_user_score) * 100).toFixed(0)} %
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[3]?.big_5_trait_full_en}
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[3]?.big_5_trait_full_th}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[3]?.big_5_trait_text_en}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[3]?.big_5_trait_text_th}
                                </span>
                            </div>


                        </div>
                    </div>
                    <div className='d-block my-auto'>
                        <div className="ocean-control">
                            <div className='d-flex flex-column'>
                                <span className="ocean-header">
                                    {((personalityGraph[4]?.big_5_user_score) * 100).toFixed(0)} %
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[4]?.big_5_trait_full_en}
                                </span>
                                <span className="ocean-text-trait my-auto">
                                    {personalityGraph[4]?.big_5_trait_full_th}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[4]?.big_5_trait_text_en}
                                </span>
                                <span className="ocean-text">
                                    {personalityGraph[4]?.big_5_trait_text_th}
                                </span>
                            </div>


                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MBTIDetailComponent;