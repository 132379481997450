import React from 'react';
import './App.scss';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginComponent from './components/login/login';

import config from './config';
import AdminComponent from './components/admin/admin';
import NavigationComponent from './components/dashboard/navigation';
import HolidaysComponent from './components/holidays/holidays';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import "react-multi-carousel/lib/styles.css";
import AdminReportComponent from './components/admin/admin-report/admin-report';
import LogOutComponent from './components/login/logout';
import AdminSearchComponent from './components/admin/admin-search/admin-search';
function App() {




  React.useEffect(() => {

    ChartJS.register(
      RadialLinearScale,
      PointElement,
      LineElement,
      Filler,
      Tooltip,
      Legend
    );
  }, [])

  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/" element={<NavigationComponent />} />
          <Route path="/logout" element={<LogOutComponent />} />
          <Route path="/admin" element={<AdminComponent />} />
          <Route path="/admin/search" element={<AdminSearchComponent /> } />
          <Route path="/admin/report/:id" element={<AdminReportComponent />} />
          <Route path="/holidays" element={<HolidaysComponent />} />
          <Route path="*" element={<NavigationComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
