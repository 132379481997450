import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import AdminApprovedComponent from '../admin-approved/admin-approved';
import AdminAwaitingComponent from '../admin-awaiting/admin-awaiting';
import AdminRejectedComponent from '../admin-rejected/admin-rejected';
import "./admin-leave.scss";


const AdminLeaveComponent = () => {
    const app = initializeApp(config.firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const [key, setKey] = useState(0);
    let navigate = useNavigate();

    const clickKey = (index: number) => {
        setKey(index);
    }


    const clickHolidays = () => {

        navigate('/holidays');
    }
    return (

        <>
            <div className="admin-leave">
                <div className="d-flex flex-column container-control">
                    
                    <div className='title-control'>

                        <h1 className='title-management'>Leave management system</h1>      
                        <button className='holidays-button' onClick={clickHolidays}>Holidays</button>                  
                    </div>
                    <div className='divider-line'></div>
                    <div className="header-control mx-auto">
                        <button className={
                            key == 0 ? "header-button header-active" : "header-button"
                        } onClick={() => clickKey(0)}>Awaiting</button>
                        <button className={
                            key == 1 ? "header-button header-active" : "header-button"
                        } onClick={() => clickKey(1)}>Approved</button>
                        <button className={
                            key == 2 ? "header-button header-active" : "header-button"
                        } onClick={() => clickKey(2)}>Rejected</button>
                    </div>
                    <div className="container content-control">
                        {
                            key === 0 ?
                                <AdminAwaitingComponent dbAccess={db}></AdminAwaitingComponent> :
                                key === 1 ?
                                    <AdminApprovedComponent dbAccess={db}></AdminApprovedComponent> :

                                    <AdminRejectedComponent dbAccess={db}></AdminRejectedComponent> 
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


export default AdminLeaveComponent;