import { OauthModel } from "../model/oauth.model";
import storage from "../storage";

class TokenService {
   
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      return user?.accessToken;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      user.accessToken = token;
      localStorage.setItem(storage.user, JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
    }
  
    setUser(user) {
      console.log(JSON.stringify(user));
      localStorage.setItem(storage.user, JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem(storage.user);
    }
  }
  
  export default new TokenService();