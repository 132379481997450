import { Modal } from "react-bootstrap";
import React, { useState } from 'react';


const ErrorPopup = ({message, isShow} :any) => {
    const [show, setShow] = useState(isShow);
    const handleClose = () => setShow(false);
    return (

        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {message}
                </Modal.Body>
              
            </Modal>
        </>
    )
}

export default ErrorPopup;