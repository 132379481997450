

const storage = {
    code_verifier: "code_verifier",
    access_token: "accessToken",
    refresh_token: "refresh_token",
    user: "user",
    company_uuid: "company_uuid"
};

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...storage
};