const test = {
    personalLeave: 15,
    sickLeave: 30,
    nextYear: "2025",
    currentYear: "2024",
    lastYear: "2023",
    title: "",
    ga_4: "G-40YMZ2R247",
    ga: "G-40YMZ2R247",
    credential: {
        apiKey: "AIzaSyDtBdt3ye1Be4n0VLxn5Xlp_ZAvy4HkLoY"
    },
    calendarConfig: {
        clientId: "164962308500-d65o25o80apkoddpeavl1m1lvesd5hb7.apps.googleusercontent.com",
        apiKey: "AIzaSyC2YaXfS9dII_ZhLgPvp9xxvliDJHIZTA4",
        scope: "https://www.googleapis.com/auth/calendar",
        discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
        ]
    },
    firebaseConfig: {
        apiKey: "AIzaSyDfocU-7vXnzK9Yj0vc4vgkk7ofkuyMlyM",
        authDomain: "test-scoutout-employee-l-5bb2a.firebaseapp.com",
        databaseURL: "https://test-scoutout-employee-l-5bb2a.firebaseio.com",
        projectId: "test-scoutout-employee-l-5bb2a",
        storageBucket: "test-scoutout-employee-l-5bb2a.appspot.com",
        messagingSenderId: "176646470304",
        appId: "1:176646470304:web:27e40af54a8204f0",
        scopes: [
            "email",
            "profile",
            "https://www.googleapis.com/auth/calendar"
        ],
        discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
        ]
    },
    clientId: '176646470304-3kngt4jri6gs4v21v5rtric58o65lg1k.apps.googleusercontent.com',
    calendarEmployeeleaveId: 'scoutout.xyz_h8q6hds2l0m3r9bd7t3v4volbg@group.calendar.google.com',
    calendarSickleaveId: 'scoutout.net_iaoqdh84ojulo98dtudtf9s71c@group.calendar.google.com',
    calendarHalfleaveId: 'scoutout.net_l5o2l4k18utn9o83vt5fsi4o3g@group.calendar.google.com',
    calendarOfficeHoliday: 'scoutout.net_pjhd662hbtotd9bule92aec768@group.calendar.google.com',
    calendarWFHleaveId: 'scoutout.net_jfcn9ifie4erf3ruba2n80n0t4@group.calendar.google.com',
    calendarWorkAtOffice: 'scoutout.net_16olbktm4b7gnvdp69fnd8k50s@group.calendar.google.com',
    domainID: 'https://asia-northeast1-test-scoutout-employee-l-5bb2a.cloudfunctions.net',
    api: {
        google_apis: 'https://www.googleapis.com/calendar/v3/calendars/',
        url: 'https://asia-southeast1-test-scoutout-employee-l-5bb2a.cloudfunctions.net',
        getCourse: '/getCourses',
        getSimilarPersonality: '/getSimilarPersonality',
        url_quiz: "https://asia-east2-scoutout-flunkey.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
        result_type: "/sixteen_personality",
        postResult: "/big5_results",
        take_quiz: 'https://quiz.scoutout.co/questions?product=leave-scoutout'
    }
};


const prod = {
    personalLeave: 15,
    sickLeave: 30,
    nextYear: "2026",
    currentYear: "2025",
    lastYear: "2024",
    title: "",
    ga_4: "G-90SSTYG5QZ",
    ga: "G-90SSTYG5QZ",
    credential: {
        apiKey: "AIzaSyCZgYcAlTF-J_djLXZtzgAHAFnEhOW7jvw"
    },
    calendarConfig: {
        clientId: "164962308500-d65o25o80apkoddpeavl1m1lvesd5hb7.apps.googleusercontent.com",
        apiKey: "AIzaSyC2YaXfS9dII_ZhLgPvp9xxvliDJHIZTA4",
        scope: "https://www.googleapis.com/auth/calendar",
        discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
        ]
    }
    ,
    firebaseConfig: {
        apiKey: "AIzaSyC2YaXfS9dII_ZhLgPvp9xxvliDJHIZTA4",
        authDomain: "scoutout-employee-leave.firebaseapp.com",
        databaseURL: "https://scoutout-employee-leave.firebaseio.com",
        projectId: "scoutout-employee-leave",
        storageBucket: "scoutout-employee-leave.appspot.com",
        messagingSenderId: "164962308500",
        appId: "1:164962308500:web:0711ef448beea518d3bf81",
        scopes: [
            "email",
            "profile",
            "https://www.googleapis.com/auth/calendar"
        ],
        discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
        ]
    },
    clientId: '164962308500-d65o25o80apkoddpeavl1m1lvesd5hb7.apps.googleusercontent.com',
    calendarEmployeeleaveId: 'scoutout.xyz_h8q6hds2l0m3r9bd7t3v4volbg@group.calendar.google.com',
    calendarSickleaveId: 'scoutout.net_iaoqdh84ojulo98dtudtf9s71c@group.calendar.google.com',
    calendarOfficeHoliday: 'scoutout.net_pjhd662hbtotd9bule92aec768@group.calendar.google.com',
    calendarHalfleaveId: 'scoutout.net_l5o2l4k18utn9o83vt5fsi4o3g@group.calendar.google.com',
    calendarWFHleaveId: 'scoutout.net_jfcn9ifie4erf3ruba2n80n0t4@group.calendar.google.com',
    calendarWorkAtOffice: 'scoutout.net_16olbktm4b7gnvdp69fnd8k50s@group.calendar.google.com',
    domainID: 'https://asia-northeast1-scoutout-employee-leave.cloudfunctions.net',
    api: {
        google_apis: 'https://www.googleapis.com/calendar/v3/calendars/',
        url: 'https://asia-southeast1-scoutout-employee-leave.cloudfunctions.net',
        getCourse: '/getCourses',
        getSimilarPersonality: '/getSimilarPersonality',
        url_quiz: "https://asia-east2-scoutout-flunkey.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
        result_type: "/sixteen_personality",
        postResult: "/big5_results",
        take_quiz: 'https://quiz.scoutout.co/questions?product=leave-scoutout'
    }

};

const config = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? prod
    : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};